export const URL = 'https://api.savetimeapply.com';

export const SocialAnalyticsApiUrl = 'https://api.savetimeapply.com/analytics';

export const ProfileApiUrl = 'https://api.savetimeapply.com/user/profile';

export const JobsApiUrl = 'https://api.savetimeapply.com/job/stats/list';

// export const ParserUrl = 'https://stagingazqna.savetimeapply.info';

export const ParserUrl = 'https://dobackendprod.savetimeapply.com';
